  import React from 'react'
  import Layout from '../../../../../components/App/Layout'
  import Navbar from '../../../../../components/App/NavbarRV'
  import Footer from '../../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../../assets/images/pages/2-6-1-1.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-assure/'>Expertise d'assuré</a></li><li><a href='/expertise-assure/expertise-judiciaire/'>Expertise contexte judiciaire</a></li><li><a href='/expertise-assure/expertise-judiciaire/assistance/'>Assistance expertise judicaire</a></li><li>Judiciaire CatNat sécheresse</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>L’expertise judiciaire sécheresse </h1>

<p>Avec la récurrence des événements de type sécheresse, de plus en plus d’Arrêtés en catastrophe naturelle pour mouvements de terrains consécutifs au retrait et réhydratation des sols sont approuvés. </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Judiciaire CatNat sécheresse" />
</div>


<p>C’est le moment pour l'assuré sinistré de faire sa déclaration de sinistre auprès de son assurance. Cependant, après le passage de l’Expert d’assurance, les conclusions de son rapport excluent la sécheresse comme facteur déterminant de vos désordres. Vous n’êtes alors pas garantie.</p>
<p>Malgré la tenue de contre-expertise en recours amiable, les avis de votre expert d’assuré et ceux de l’expert d’assurance divergent et chacun reste sur ses positions sans entrevoir d’accords amiables.</p>

<p>Vous avez alors le droit à un ultime recours, celui de l’<strong><u>expertise judiciaire</u></strong>, procédure qui va faire intervenir un expert judiciaire mandaté par le tribunal compétent et qui aura pour mission de trancher le litige sur le plan technique.</p>

<p>Être accompagné par un Expert technique est alors déterminant.</p>
<p>Ce dernier va définir une stratégie de défense de votre dossier et apporter un argumentaire technique à votre avocat.</p>

<p>Le cabinet RV EXPERTISES vous accompagne en Expertise judiciaire</p>

<p>Le cabinet RV Expertises vous accompagne lors des différentes phases d’expertise judiciaire :</p>
<ul>
<li>étude préalable de votre dossier,</li>
<li>présentiel en accedit pour défendre vos intérêts,</li>
<li>notes de synthèse et stratégie.</li>
</ul>

<p>L’accédit est une opération réunissant les différentes parties dans le respect du contradictoire. C’est l’occasion pour votre Expert de défendre votre dossier techniquement et d’exprimer ses arguments techniques auprès de l’Expert Judiciaire. Le choix d’un bon Expert est alors déterminant !</p>

<h2>Notre intervention</h2>

<p>Le cabinet RV Expertises s’est spécialisé dans l’accompagnement en expertise judiciaire et garantit un soutien technique à la défense de votre dossier</p>
<ul>
<li>accompagnement lors des accédits en présentiel,</li>
<li>échanges avec les différentes parties et l’Expert judiciaire dans le respect du contradictoire,</li>
<li>constitution des pièces du dossier (état hypothécaire, attestation notariée),</li>
<li>détermination des travaux de reprise consécutifs au sinistre sécheresse.,</li>
<li>chiffrage estimatif des travaux,</li>
<li>contact des entreprises spécialisées (reprise en sous oeuvre, second oeuvre, toiture)</li>
</ul>

<p>Faites vous accompagnés d’Experts techniques et sapiteurs compétents pour une défense optimale de votre dossier, lors des phases d’expertise judiciaires.</p>
<p>N’hésitez pas, contactez le cabinet RV Expertises, nous étudierons votre dossier avec la plus grande attention.</p>
                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      

                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                    </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details